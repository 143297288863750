import "lazysizes";
import "picturefill";
// var $ = require('jQuery');

$('a[href^="#"]').click(function () {
  const windowWidth = $(window).width();
  const windowSm = 835;
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? "html" : href);
  let position = 0;
  if (windowWidth > windowSm) {
    position = target.offset().top - 90;
  } else {
    position = target.offset().top - 55;
  }
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

//アコーディオン
$(".js-menu__item__link").each(function () {
  $(this).on("click", function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

//ヘッダーメニュー
$(".header__spBtn").on("click", function () {
  $("html").toggleClass("scroll-prevent");
  if ($(this).hasClass("is-active")) {
    $(this).removeClass("is-active");
    $(".header__spMenu").removeClass("is-active");
  } else {
    $(this).addClass("is-active");
    $(".header__spMenu").addClass("is-active");
  }
});

$(window).scroll(function () {
  var windowHeight = $(window).height();
  var scrollThreshold = windowHeight * 0.9;
  var scroll = $(window).scrollTop();
  if (scroll >= scrollThreshold) {
    $(".header").addClass("is-fixed");
  } else {
    $(".header").removeClass("is-fixed");
  }
});

$(".faq__item__q").each(function () {
  $(this).on("click", function () {
    $("+.faq__item__a", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

$('[data-modal-target]').click(function() {
  console.log("test");
  var modalTarget = $(this).data('modal-target');
  $(modalTarget).fadeIn(); 
});

$('.cta__modal__close').click(function() {
  var modalTarget = $(this).data('modal-target');
  $(modalTarget).fadeOut(); 
});

